.endless-slider-wrapper {
  position: relative;
  width: 100%;
  height: 151px;
  overflow: hidden;
}

.endless-slider-container {
  display: flex;
  height: 135px;
  position: absolute;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  width: calc(200% + 1rem); /* Ensure container width includes the gap */
  gap: 1rem;
}

.endless-slider-container:hover {
  animation-play-state: paused !important;
}

.endless-slider-item {
  flex: 0 0 auto;
  height: 135px;
  min-width: 240px;
}

@media (max-width: 992px) {
  .endless-slider-item {
    height: 90px;
    min-width: 160px;
  }
  .endless-slider-wrapper {
    height: 108px;
  }
  .endless-slider-container {
    height: 108px;
  }
}

@keyframes endless-slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
