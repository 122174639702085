.toast {
  position: fixed;
  bottom: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast > div {
  flex: 1; /* Ensure the child div takes the full width */
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast-link i {
  margin-right: 0.5rem;
}

.toast .btn-highlight {
  border: none;
  font-weight: 400;
  padding: 0.75rem 1.5rem;
  color: var(--primary-color);
  width: 100%; /* Ensure the button takes full width */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

a.toast-link.btn-highlight {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
