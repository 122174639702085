.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-auto-flow: dense;
    grid-gap: 0.5rem;
    width: 100%;
    padding: 1rem;
    z-index: 0;
  }
  
  @media screen and (max-width: 992px) {
    .gallery {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media screen and (max-width: 768px) {
    .gallery {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .gallery-image {
      aspect-ratio: 1 / 1;
    }
  }
  
  .gallery-image-container {
    overflow: hidden;
    border-radius: calc(var(--button-border-radius) / 2);
    position: relative;
    aspect-ratio: 1 / 1;
  }
  
  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(1);
    position: relative;
  }
  
  .gallery-image:hover {
    transform: scale(1.1);
    z-index: 11; /* Ensure hover still brings image to top */
    cursor: pointer;
  }
  
  .gallery-fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 2rem;
    background-color: var(--background-color-transparent);
    backdrop-filter: blur(5px);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .gallery-image-fullscreen {
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    border-radius: var(--button-border-radius);
  }
  
  .gallery-fullscreen-container button {
    position: absolute;
    top: 1rem;
    right: 2rem;
  }
  