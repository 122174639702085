.contact-form-container {
    margin: 1rem;
    border-radius: var(--button-border-radius);
    width: 100%;
  }
  
  .contact-form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .contact-form label {
    font-weight: bold;
  }
  
  .contact-form > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    flex: 1 0 calc(50% - 1rem);
    align-items: flex-start;
  }
  
  @media (max-width: 768px) {
    .contact-form > div {
      flex: 0 1 100%;
    }
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 1rem;
    width: calc(100% - 2rem);
    background-color: var(--background-color);
    border: 2px solid var(--secondary-color);
    color: var(--primary-color);
    border-radius: var(--button-border-radius);
    font-size: 16px;
    transition: border 0.3s ease-in-out;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    border-color: var(--active-color);
    outline: none;
  }
  
  .contact-textarea {
    min-height: 6rem;
    resize: none;
  }
  
  .contact-submit-btn {
    background: var(--primary-color);
    color: var(--secondary-color);
    border: none;
    padding: 1rem;
    cursor: pointer;
    border-radius: var(--button-border-radius);
    width: 100%;
    font-size: 16px;
    transition: background 0.3s ease-in-out;
  }
  
  .contact-submit-btn:hover {
    background: var(--active-color);
  }
  
  .contact-submit-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .contact-success {
    text-align: center;
    padding: 1rem;
    border-radius: var(--button-border-radius);
    border: 1px solid var(--success-color);
    color: var(--success-color);
  }
  
  .offline-warning {
    color: red;
    font-size: 14px;
  }
  
  .error-message {
    width: 100%;
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .contact-form-container.success svg {
    font-size: 3rem;
    color: var(--success-color);
  }
  