

#darkmode-toggle {
  padding: 0;
  background: transparent;
}

#darkmode-toggle:hover {
  border: none;
}

#darkmode-toggle > div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: .125rem;
}

#darkmode-toggle svg {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.5rem;
  color: var(--primary-color);
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
}
