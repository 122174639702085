.parallax-container {
    width: 100%;
    overflow: clip;
}

.fullscreen-container {
    height: 150vh;
    width: 100%;
    background-color: var(--secondary);
    position: relative;
    z-index: 1;
}

.fullscreen-description {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
}

.fullscreen-image {
    width: 100%;
}

.fullscreen-description-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    z-index: 1;
}

.fullscreen-product-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.fullscreen-product-image img {
    width: 250px;
}

@media (max-width: 768px) {
    .fullscreen-product-image img {
        width: 125px;
    }
}