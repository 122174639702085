:root {
    --primary-bg: #1e3c72;
    --secondary-bg: #2a5298;
    --text-color: white;
}

.weather-widget {
    width: 300px;
    padding: 20px;
    background: linear-gradient(to bottom, var(--primary-bg), var(--secondary-bg));
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    text-align: center;
    color: var(--text-color);
    position: relative;
}

.weather-icon {
    width: 120px;
    margin: 0 auto;
}

.weather-info h2 {
    font-size: 2rem;
    margin: 10px 0;
}

.weather-info p {
    font-size: 1.2rem;
    text-transform: capitalize;
}
