.modal {
  position: fixed;
  inset: 0;
  background-color: var(--frosted-bg);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  z-index: 5;
}

.modal-container {
  margin-top: 1rem;
  margin-bottom: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  z-index: 10;
  overflow: hidden;
  transition: all ease-in 0.25s;
  gap: 1rem;
  width: 100%;
  max-width: 500px;
}

@media(min-width: 768px) {
  .modal-container {
    margin: 0 auto;
  }
}

.modal .close-btn {
  position: fixed;
  top: 2rem;
  right: 2rem;
  font-size: 2rem;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 1050px) {
  .modal .close-btn {
    display: none;
  }
}

.hide {
  display: none;
}

.modal p {
  margin: 0.5rem 0;
}

.modal a {
  display: flex;
  gap: .5rem;
}

#modal .modal-container .userform-container {
  margin: 0;
  padding: 0;
}

a#modal-link {
  text-transform: uppercase;
  margin: 1rem 0;
}

#modal .modal-container > div > svg {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--body-color);
}