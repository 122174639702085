.preloader {
    position: fixed;
    inset: 0;
    width: 100%;
    z-index: 10000;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 1;
    background: var(--active-color);
  }
  .preloader.loaded {
    opacity: 0;
    visibility: hidden;
  }
  .preloader .spinner_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  
  .preloader .spinner {
    width: 100px;
    height: 100px;
    background-image: url(/src/images/jk_logo_black.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  [data-theme="dark"] .preloader .spinner {
    background-image: url(/src/images/jk_logo_white.png);
    
  }
  
  


.upper-div,
.lower-div {
  width: 100%;
  height: 50%;
  background-color: var(--secondary-color);
  z-index: 1;
}
