.hero {
  width: 100%;
  min-height: 30vh;
  background-image: var(--hero-background-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: var(--button-border-radius);
  position: relative;
}

.hero:after {
  content: '\A';
  position: absolute;
  width: 100%; 
  height:100%;
  top:0; 
  left:0;
  background: var(--background-color-transparent-less);
}

.fullwidth .hero {
  border-radius: 0;
  margin: 0;
}

.hero-container {
  border-radius: var(--button-border-radius);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}

@media (max-width: 1140px) {
  .hero-container {
    height: 100%;
    margin-top: 6rem;
    width: unset;
  }
}

.hero h1 {
  text-transform: uppercase;
}

.hero p,
.hero h1 {
  max-width: 40rem;
  width: 100%;
}

.hero-container a {
  margin-top: 1.5rem;
  display: block;
}

.hero-container a span {
  font-weight: bold;
}

.frosted {
  background-color: var(--frosted-bg);
  backdrop-filter: blur(5px);
}

.bg-white {
  background-color: #fff;
}

.button-animation {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.button-animation:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10rem;
  z-index: -2;
}

.button-animation:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: darken(#576f72 15%);
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
}

.button-animation:hover {
  color: #fff;
}

.button-animation:hover:before {
  width: 100%;
}

.background-gradient {
  background: var(--primary);
  background: var(--primary-gradient);
}
.hero-button-icon {
  margin-right: .5rem;
}