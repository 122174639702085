.zoomin-wrapper {
    height: 125vh;
    width: 100%;
    background-color: var(--secondary-color);
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;
    overflow: hidden; /* Ensure it's scrollable */
    padding-bottom: 100px;
}

.zoomin-container {
    flex: 1 0 25%;
    max-width: 25%;
}

.zoomin-container .fullscreen-image {
    aspect-ratio: 16 / 9;
    width: 100%;
    position: relative;
}

@media (max-width: 992px) {
    .zoomin-container .fullscreen-image {
        aspect-ratio: 9 / 16;
    }
    .zoomin-container {
        flex: 1 0 33.333%;
        max-width: 33.333%;
    }
}

.nav-button {
    position: absolute;
    z-index: 3;
}

.nav-button.next-button {
    right: 1rem;
}
.nav-button.prev-button {
    left: 1rem;
}
