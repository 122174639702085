.horizontal-scroll-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.horizontal-scroll-section-content {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 80%;
  width: 100%;
  max-width: 1140px;
  padding: 2rem;
  border: 2px solid var(--secondary-color);
  border-radius: var(--button-border-radius);
}

.horizontal-scroll-section-content h2 {
  font-size: 7rem;
  z-index: 1;
  margin-bottom: auto;
  color: var(--secondary-color);
  margin-right: auto;
}

.horizontal-scroll-section-content img {
  display: none;
  width: 50%;
  max-width: 600px;
  border-radius: calc(var(--button-border-radius) / 2);
}

@media (max-width: 992px) {
  .horizontal-scroll-section-content h2 {
    font-size: 2rem;
  }
  .horizontal-scroll-section-content {
    flex-direction: column;
    gap: 2rem;
  }
  .horizontal-scroll-section-container {
    padding: 2rem;
  }
}

