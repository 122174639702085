@import "./fonts.css";

:root {
  --background-color: #f0f0f0;
  --background-color-transparent: color-mix(
    in srgb,
    var(--background-color),
    transparent 25%
  );
  --background-color-transparent-less: color-mix(
    in srgb,
    var(--background-color),
    transparent 30%
  );
  --primary-color: #1e1e1e;
  --primary-color-transparent: color-mix(
    in srgb,
    var(--primary-color),
    transparent 85%
  );
  --secondary-color: #eaeaea;
  --secondary-color-transparent: color-mix(
    in srgb,
    var(--secondary-color),
    transparent 25%
  );
  --tertiary-color: #0d0d0d;
  --headline-color: #1e1e1e;
  --body-color: #1e1e1e;
  --highlight-color: 169, 151, 223;
  --margin-top: 3.5rem;
  --button-border-radius: 1rem;
  --navitem-border-radius: 2rem;
  --transition: all 0.25s ease-in;
  --topbar-height: 3rem;
  --text-bright: #1e1e1e;
  --text-dark: #f0f0f0;
  --icon-color: #1e1e1e;
  --icon-color-navbar: #f0f0f0;
  --menu-color: #f0f0f0;
  --topnav-container: var(--active-color);
  --primary-gradient: linear-gradient(
    45deg,
    var(--primary-color) 0%,
    var(--tertiary-color) 100%
  );
  --secondary-gradient: linear-gradient(
    45deg,
    var(--background-color) 0%,
    var(--secondary-color) 100%
  );
  --menu-container: linear-gradient(
    45deg,
    var(--background-color-transparent) 0%,
    var(--secondary-color-transparent) 100%
  );
  --footer-color: var(--secondary-gradient);
  --frosted-bg: rgb(239 239 239 / 75%);
  --hero-background-image: url(images/hero-bg.webp);
  --active-color: #00a6fb;
  --box-shadow: rgba(34, 108, 224, 0.4) 0px 5px,
    rgba(34, 108, 224, 0.3) 0px 10px, rgba(34, 108, 224, 0.2) 0px 15px,
    rgba(34, 108, 224, 0.1) 0px 20px, rgba(34, 108, 224, 0.05) 0px 25px;
  --success-color: #01ef80;
  --image-shadow: 255, 255, 255;
  --shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#root {
  overflow: clip;
}

.content {
  position: relative;
}

input[type="color"] {
  appearance: none;
  -webkit-appearance: none;
  width: 2.625rem !important;
  height: 2.625rem !important;
  max-height: 2.625rem;
  border: 2px solid #000;
  border-radius: 50%;
  padding: 0 !important;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

input.input-primary-color {
  background-color: var(--primary-color);
}

input.input-secondary-color {
  background-color: var(--secondary-color);
}

input.input-tertiary-color {
  background-color: var(--tertiary-color);
}

input.input-background-color {
  background-color: var(--background-color);
}

input.input-active-color {
  background-color: var(--active-color);
}

[data-theme="dark"] {
  --background-color: #121212 !important;
  --background-color-transparent: color-mix(
    in srgb,
    var(--background-color),
    transparent 25%
  ) !important;
  --background-color-transparent-less: color-mix(
    in srgb,
    var(--background-color),
    transparent 30%
  ) !important;
  --primary-color: #cfcfcf !important;
  --primary-color-transparent: color-mix(
    in srgb,
    var(--primary-color),
    transparent 85%
  ) !important;
  --secondary-color: #1e1e1e !important;
  --secondary-color-transparent: color-mix(
    in srgb,
    var(--secondary-color),
    transparent 25%
  ) !important;
  --tertiary-color: #f0f0f0 !important;
  --headline-color: #f0f0f0 !important;
  --body-color: #f0f0f0 !important;
  --text-dark: #f0f0f0 !important;
  --text-bright: #f0f0f0 !important;
  --icon-color: #cfcfcf !important;
  --icon-color-navbar: #f0f0f0 !important;
  --menu-container: linear-gradient(
    45deg,
    var(--background-color-transparent) 0%,
    var(--secondary-color-transparent) 100%
  ) !important;
  --frosted-bg: rgba(24, 24, 24, 0.75) !important;
  --menu-color: #fff !important;
  --primary-gradient: linear-gradient(
    45deg,
    var(--primary-color) 0%,
    var(--tertiary-color) 100%
  ) !important;
  --image-shadow: 0, 0, 0;
}

[data-theme="dark"] input[type="color"] {
  border: 2px solid #fff;
}

*:empty {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1rem 0;
  letter-spacing: 0.05rem;
  line-height: 1.2;
  color: var(--tertiary-color);
}

a,
button {
  font-weight: bold;
  color: var(--primary-color);
}

a,
span,
p,
li,
button {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media (max-width: 768px) {
  a,
  span,
  p,
  li,
  button {
    font-size: calc(1rem * 0.875);
  }
}

h1,
h1 span {
  font-size: 3.25rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  h1,
  h1 span {
    font-size: calc(2.25rem * 0.875);
    font-weight: bold;
  }
}

h2,
h2 span {
  font-size: 2rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  h2,
  h2 span {
    font-size: calc(2rem * 0.875);
    font-weight: bold;
  }
}

h3,
h3 span {
  font-size: 1.75rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  h3,
  h3 span {
    font-size: calc(1.75rem * 0.875);
    font-weight: bold;
  }
}

h4,
h4 span {
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  h4,
  h4 span {
    font-size: calc(1.5rem * 0.875);
    font-weight: bold;
  }
}

h5,
h6,
h5 span,
h6 span {
  font-size: 1.25rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  h5,
  h5 span,
  h6, h6 span {
    font-size: calc(1.25rem * 0.875);
    font-weight: bold;
  }
}

body {
  margin: 0;
  font-size: 16px;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-weight: normal;
}
a {
  border: none;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  font-weight: bold;
}

a,
button,
input {
  cursor: pointer;
}

input::placeholder,
.fullscreen-password::placeholder {
  color: var(--primary-color);
  opacity: 1; /* Firefox */
}

[data-theme="dark"] input::placeholder,
.fullscreen-password::placeholder {
  color: var(--primary-color);
  opacity: 1; /* Firefox */
}

input[type="text"],
input[type="number"],
input[type="date"],
input[type="email"],
input[type="phone"],
input[type="password"],
input[type="time"],
textarea {
  font-size: 1rem;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

a > i {
  margin-right: 0.5rem;
}

#root {
  background-color: var(--background-color);
}

/* Page Styles */

.page {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  max-width: 1140px;
  padding: 1rem;
  margin: 0 auto;
  background-color: var(--background-color);
}

@media (max-width: 1050px) {
  .page {
    padding: 1rem;
  }
}

/* Box Sizing */
.full {
  flex: 0 1 100%;
}

.half {
  flex: 0 1 calc(50% - 5rem);
  max-width: calc(50% - 5rem);
}

@media (max-width: 992px) {
  .half {
    flex: 0 1 100% !important;
    max-width: 100%;
  }
}

.two-thirds {
  flex: 0 1 calc(66.66667% - 5rem);
  max-width: calc(66.66667% - 5rem);
}

@media (max-width: 992px) {
  .two-thirds {
    flex: 0 1 100% !important;
    max-width: 100%;
  }
}

.one-third {
  flex: 0 1 calc(33.333% - 5rem);
  max-width: calc(33.333% - 5rem);
}

@media (max-width: 992px) {
  .one-third {
    flex: 0 1 100% !important;
    max-width: 100%;
  }
}

.one-quarter {
  flex: 0 1 calc(25% - 5rem);
  max-width: calc(25% - 5rem);
}

@media (max-width: 992px) {
  .one-quarter {
    flex: 0 1 calc(50% - 5rem) !important;
    max-width: calc(50% - 5rem);
  }
}
@media (max-width: 768px) {
  .one-quarter {
    flex: 0 1 100% !important;
    max-width: 100%;
  }
}

/* Button Styles */

button,
input {
  border-radius: var(--button-border-radius);
}

.btn-dark {
  text-transform: uppercase;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: var(--button-border-radius);
  transition: var(--transition);
  border: 1px solid;
}

.btn-highlight {
  text-transform: uppercase;
  color: var(--secondary-color);
  background-color: var(--active-color);
  border-radius: var(--button-border-radius);
  transition: var(--transition);
  border: 2px solid;
  font-weight: bold;
}

@media (min-width: 992px) {
  .btn-dark:hover {
    color: var(--primary-color);
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
  }
  .btn-outline:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    transition: var(--transition);
  }
  .btn-highlight:hover {
    text-transform: uppercase;
    color: var(--active-color);
    background-color: var(--secondary-color);
    border-radius: var(--button-border-radius);
    transition: var(--transition);
    border: 1px solid;
  }
}

.btn-outline {
  background-color: initial;
  border: 2px solid #eaeaea;
  border: 2px solid var(--secondary-color);
  border-radius: 0.75rem;
  border-radius: var(--button-border-radius);
  color: #1e1e1e;
  color: var(--text-bright);
  font-weight: bold;
  text-transform: uppercase;
}

.btn-outline span {
  font-weight: bold;
}

.default-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

p {
  color: var(--body-color);
}

a {
  text-decoration: none;
}

.slide-in-right-menu {
  transform: translateY(0);
}

.slide-in-right.border {
  border: 1px solid var(--text-dark);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-out-right-menu {
  transform: translateY(calc(200% + 0.5rem));
  position: relative;
}
.slide-out-right-menu.pulse::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(var(--primary-color), var(--primary-color)),
    linear-gradient(var(--secondary-color), var(--secondary-color)),
    linear-gradient(var(--tertiary-color), var(--tertiary-color)),
    linear-gradient(var(--secondary-color), var(--secondary-color));
  animation: rotate 10s linear infinite;
}

.slide-out-right-menu::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: var(--menu-container);
  border-radius: var(--button-border-radius);
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(calc(100% + 0.5rem));
    opacity: 1;
  }
}

@keyframes drawBorderFromCenter {
  0% {
    background-size: 0 2px, 0 0, 100% 100%;
  }
  20% {
    background-size: 100% 2px, 100% 0, 100% 100%;
  }
  66% {
    background-size: 100% 2px, 100% 98%, 100% 100%;
  }
  99% {
    background-size: 100% 2px, 100% 98%, 0 2px;
  }
}

.slide-in-left {
  animation: slide-in-left 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.horizontal-shake {
  animation: horizontal-shaking 0.5s 1;
  animation-delay: 0.1s;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(25px);
  }
  50% {
    transform: translateX(-25px);
  }
  75% {
    transform: translateX(25px);
  }
  100% {
    transform: translateX(0);
  }
}

.fade-in-left {
  animation: fade-in-left 0.25s ease-in;
}

@keyframes fade-in-left {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

canvas#canvas,
img#exportedImage {
  display: none;
}

/* Animation of Boxes */
.hidden-element {
  opacity: 0;
  transition: transform 0.5s, filter 0.5s, opacity 0.5s;
  transition-delay: 0.25s;
  transform: scale(0.5);
  filter: blur(0.5rem);
}
.shown-element {
  opacity: 1;
  transform: scale(1);
  filter: blur(0);
}

/* Typing Animation */
.typing-animation {
  width: 18ch;
  animation: typing 1.5s steps(11), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2rem;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

/* Snap Scroll */

.snap-scroll {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.bold {
  font-weight: bold;
}

.iframe-xs-barbershop {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: none;
  border-radius: var(--button-border-radius);
  margin: 1rem;
  filter: grayscale(1);
}

button#toggle-btn {
  color: var(--primary-color);
  font-size: 2rem;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
}

#toggle-btn i {
  width: 2rem;
  height: 2rem;
  font-size: 1.25rem;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}

#toggle-btn:hover i {
  color: var(--background-color);
  background-color: var(--primary-color);
  border: 1px solid var(--background-color);
}

#toggle-btn:hover {
  border: none;
}
