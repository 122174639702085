.login-container {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-height: 600px;
}

/* 🌟 Main Login Container */
.login-container {
    background: var(--secondary-color);
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
  }
  /* 🔹 Title */
  .login-title {
    font-size: 26px;
    font-weight: bold;
    color: var(--text-bright);
    margin-bottom: 15px;
  }
  
  /* 🖊️ Input Fields */
  .login-input {
    width: 100%;
    padding: .75rem !important;
    margin: 10px 0;
    border: 2px solid var(--secondary-color);
    border-radius: 8px;
    transition: border 0.3s ease-in-out;
    font-size: 16px;
    width: 100%; 
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
  }

  input::placeholder {
    color: var(--primary-color) !important; /* Adjust to fit your theme */
    opacity: 0.7; /* Make it slightly transparent */
  }
  
  .login-input:focus {
    border-color: var(--active-color);
    outline: none;
  }
  
  /* 🔗 Switch Options (Forgot PW & Signup) */
  .switch-options {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  
  /* ❌ Error Text */
  .error-text {
    color: var(--active-color);
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .privacy-note a {
    padding: 0;
  }

  button.btn.btn-highlight.btn-login {
    font-size: .75rem;
    padding: 0;
    border: none;
    color: var(--text-dark)
}

button.btn.btn-highlight.btn-login:hover {
    background: none !important;
    color: var(--secondary-color)
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.login-input {
  width: 100%;
  padding-right: 40px; /* Leaves space for the icon */
}

.toggle-password {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  font-size: 1rem;
  color: var(--primary-color);
}