.topbar {
  background: var(--topnav-container);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 5;
  margin: 1rem;
  padding: 0.75rem 1rem;
  border: 1px solid var(--primary-color);
  border-radius: 2rem;
}

.topbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.topbar-container div.user-icon {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  color: var(--primary-color);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  transition: var(--transition);
  padding: 0.25rem;
  text-align: center;
  font-weight: bold;
}
.topbar-container p {
  color: var(--text-dark);
  margin: 0;
}

.topbar .user-settings > svg {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  color: var(--primary-color);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}

.topbar button.user-settings {
  background-color: transparent;
  color: var(--icon-color-navbar);
  display: flex;
  padding: 0;
}

@media (max-width: 1350px) {
  div#topbar {
    display: none;
  }
}

@media (max-width: 992px) {
  .topbar-user-greeting,
  .topbar-user-appointment > span {
    display: none !important;
  }
  .user-icon {
    margin-right: auto !important;
  }
}
