.fullscreen-overlay {
  width: 100%;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  background-color: var(--secondary-color);
  background: linear-gradient(300deg, #00a6fb, #b0c6d2, #0582ca);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
  display: block;
}

[data-theme="dark"] .fullscreen-overlay {
  background-color: var(--secondary-color);
  background: linear-gradient(300deg, #00a6fb, #003554, #0582ca);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
  display: block;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.fullscreen-overlay .fullscreen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.fullscreen-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
}

.fullscreen-overlay__logo {
  max-width: 200px;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .fullscreen-overlay__logo {
    width: 100px;
  }
}

.fullscreen-unlock {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

input.fullscreen-password {
  background-color: var(--secondary-color) !important;
  border: 2px solid var(--secondary-color) !important;
  border-radius: var(--button-border-radius);
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 16px;
  padding: 0.75rem !important;
  transition: border 0.3s ease-in-out;
  width: calc(100% - 2rem);
  width: 100%;
  text-align: center;
  color: var(--tertiary-color)
}

input.fullscreen-password:focus {
  border-color: var(--active-color) !important;
  outline: none;   
}

.fullscreen-overlay h2,
.fullscreen-overlay p {
  text-align: center;
}

.fullscreen-overlay p a {
  padding: 0;
}

.fullscreen-password-message {
  color: red;
  font-weight: bold;
}
.accordion-container {
  width: 100%;
}

.fullscreen-unlock_button {
  background-color: transparent;
  border: 2px solid var(--secondary-color) !important;
  border-radius: var(--button-border-radius);
  color: var(--secondary-color);
  font-weight: bold;
  text-transform: uppercase;
}
