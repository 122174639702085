.footer {
  background: var(--footer-color);
  border-top: 2px solid var(--secondary-color);
}

.footer > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
  padding: 2rem;
}

.footer > div:nth-child(2) {
  justify-content: center;
}

.footer > div > div > * {
  color: var(--tertiary-color);
  text-decoration: none;
}

.footer > div > * {
  color: var(--primary-color);
  text-decoration: none;
}

.footer h5.footer-overview {
  margin: 0.5rem 0 1.5rem 0;
}

.footer h5 {
  margin: 0.5rem 0;
}

.footer-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 1rem;
  margin: 1rem;
}

.box-full {
  width: 100%;
}

.footer-logo_container {
  flex: 0 0 100%;
  padding: 1rem;
  margin: 1rem;
}

.footer-first-container {
  flex-wrap: wrap;
}

.footer-first-container .footer-box {
  flex: 0 1 calc(33.333% - 5rem);
  max-width: calc(33.333% - 4rem);
  padding: 0;
}
.footer-first-container .footer-logo_container {
  padding: 0;
}

.footer-logo {
  max-width: 5rem;
}

.footer a {
  padding: 0;
}

@media screen and (max-width: 1080px) {
  .footer > div {
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
    padding: 1rem;
  }
  .footer-first-container .footer-box {
    flex: 0 1 100%;
    max-width: 100%;
  }
}

.footer-second-container .footer-box {
  padding: 0;
}

.footer-text {
  margin-top: 0;
}

.footer h5.footer-overview {
  margin: .5rem 0;
}