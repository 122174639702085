.edit-page-button {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 1001;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;
    margin: 0;
}

.edit-page-button:hover {
    padding: 1rem;
    border-radius: 50%;
}