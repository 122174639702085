.to-top-button {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  display: flex;
  z-index: 2;
}


.to-top-button-items-container {
  position: absolute;
  bottom: -.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: .5rem;
  justify-content: space-between;
  background: var(--menu-container);
  padding: 0.5rem 1rem;
  border-radius: var(--navitem-border-radius);
  z-index: 1;
}

.btn-to-top {
  z-index: 5;
  padding: 0;
  background-color: transparent;
  
}

.btn-to-top > div {
  width: 42px;
  height: 42px;
  background-color: var(--active-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}


button.btn-to-top > div > svg {
  width: 1.25rem;
  height: 1.25rem;
  padding: .5rem;
}


.to-top-button-items > a, .to-top-button-items > div {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: .125rem;
}

.to-top-button-items > a > svg , .to-top-button-items > div > svg {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.5rem;
  color: var(--primary-color);
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  
}

#to-top-button-items-container > div:nth-child(2) {
  margin-right: 3rem;
}

.greyed-out {
  opacity: 0.25;
}