/* Screensaver Styles */
.screensaver {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--frosted-bg, rgba(0, 0, 0, 0.5)); /* Ensure opacity */
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100000;
}

/* Fade Animations */
.fade-in {
  opacity: 1;
  pointer-events: all;
}

.fade-out {
  opacity: 0;
  pointer-events: none;
}

/* Centered Message */
.message {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  border-radius: 10px;
}

/* Headline */
.message h1 {
  font-size: 2.75rem;
  margin-bottom: 10px;
  color: var(--primary-color, white);
}

/* Subtitle */
.message p {
  font-size: 1.25rem;
  color: var(--primary-color, white);
}
