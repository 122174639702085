.navbar-links_container .active {
  font-weight: bold;
  border: 1px solid var(--active-color);
  border-radius: var(--navitem-border-radius);
  background-color: var(--active-color);
  color: var(--secondary-color);
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: var(--menu-container);
  padding: 0.5rem 1rem;
  margin: 1rem auto;
  border-radius: 2rem;
  justify-content: center;
  width: fit-content;
  z-index: 5;
  position: relative;
  overflow: clip;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-links {
  display: flex;
  flex: 1;
  margin-right: 1rem;
  align-items: center;
}

.navbar-links_logo {
  margin-right: 1rem;
}

.navbar-links_logo a {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-links_logo img {
  width: 2rem;
}

@media (max-width: 1500px) {
  .navbar-links_logo img {
    width: 2rem;
  }
}

.navbar-links_container {
  display: flex;
  flex-direction: row;
}

.navbar-menu_container-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 100%;
}

.navbar-menu_container-links #main-logo {
  width: 3rem;
  margin-bottom: 2rem;
}

.navbar-links_container p,
.navbar-links_container a {
  text-transform: uppercase;
  cursor: pointer;
  color: var(--text-bright);
  text-decoration: none;
  display: block;
  white-space: nowrap;
}

.navbar-links_container a:hover {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: var(--navitem-border-radius);
}

.navbar-menu_container p,
.navbar-menu_container a {
  text-transform: uppercase;
  cursor: pointer;
  margin: 0;
  color: var(--body-color);
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.25rem 0;
}

.navbar-links_container p {
  margin: 0 0.125rem;
}

.navbar-links_container a {
  padding: 0.5rem 1rem;
  border: 1px solid var(--primary-color);
  border-radius: var(--navitem-border-radius);
}

.navbar-menu_container .navbar-menu_container-links p a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.navbar-menu_container .navbar-menu_container-links p a svg {
  margin-right: 0.5rem;
  padding: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.navbar-menu_container .navbar-menu_container-links p a span {
  font-size: 2rem;
}

.navbar-menu_container .navbar-menu_container-links p a.active i {
  background-color: var(--active-color);
}

.navbar-links_container a i {
  display: none;
}

.navbar-sign {
  display: flex;
  gap: 0.5rem;
}

.navbar-sign a {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  padding: 0.125rem;
}

.navbar-sign a:hover span {
  color: var(--primary-color);
}
.navbar-sign a span {
  color: var(--background-color);
}
.navbar-menu_container-links-sign a {
  text-transform: uppercase;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.navbar-sign a svg {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.5rem;
  color: var(--primary-color);
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-menu_container-links-sign a i {
  font-size: 1.25rem;
}

.navbar-menu_container-links-sign a {
  border: 1px solid var(--primary-color);
  border-radius: 2rem;
  padding: 0.25rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.navbar-sign a:hover i {
  color: var(--background-color);
  background-color: var(--primary-color);
  border: 1px solid var(--background-color);
}

.navbar-sign a span,
.navbar-menu_container-links-sign a span {
  font-size: 0.75rem;
}

.navbar-sign a span {
  white-space: nowrap;
}

.navbar-sign .button-contrast a,
.navbar-menu_container-links-sign .button-contrast a {
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
}

.navbar-menu_container-links-sign button {
  margin: 0;
}

.navbar-sign button:hover,
.navbar-menu_container-links-sign button:hover {
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
}

.navbar-sign .button-contrast button:hover,
.navbar-menu_container-links-sign .button-contrast button:hover {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
}

.navbar-menu__icon-container > div > svg,
.navbar-menu__settings-container > div > svg {
  font-size: 1.25rem;
  color: var(--primary-color);
}

.navbar-menu__icon-container > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu svg,
svg.menu-icon {
  cursor: pointer;
}

.navbar-menu_container-overlay {
  position: fixed;
  inset: 0;
  background-color: var(--frosted-bg);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.navbar-menu_container {
  margin-top: 1rem;
  margin-bottom: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  z-index: 10;
  overflow: hidden;
  transition: all ease-in 0.25s;
}
.navbar-menu_container-links-sign {
  display: none;
}

.navbar-menu_container img#main-logo {
  width: 5rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1140px) {
  .navbar {
    background: transparent;
    border: none;
    padding: 0.5rem 0;
  }
  .navbar-links {
    margin-right: 0;
  }
  .navbar-menu {
    margin-left: 0.5rem;
  }
  .navbar-current-page {
    background: var(--menu-container);
    border: 1px solid var(--primary-color);
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  @media (max-width: 350px) {
    .navbar-current-page {
      display: none;
    }
  }

  .navbar-links_logo a {
    background: var(--menu-container);
    border: 1px solid var(--primary-color);
    border-radius: 2rem;
    padding: 0.25rem;
  }
  .navbar-menu__icon-container,
  .navbar-menu__settings-container {
    background: var(--menu-container);
    border: 1px solid var(--primary-color);
    border-radius: 2rem;
    padding: 0.25rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .navbar-links_container {
    display: none;
  }

  .navbar-menu {
    display: flex;
    gap: 0.5rem;
  }
  .navbar-menu > div > i {
    font-size: 2rem;
    color: var(--primary-color);
    cursor: pointer;
  }
  .navbar {
    width: unset;
    margin-inline: 2rem;
  }

  .navbar-sign {
    display: none;
  }

  .navbar-menu_container-links-sign {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 1rem;
  }

  .navbar-menu_container-links-sign a {
    margin: 0;
  }
}

.menu-icon {
  color: var(--text-bright);
}

.navbar-menu_container-links a.active {
  color: var(--active-color);
}
.navbar-menu-container-side-toggle {
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  color: var(--text-bright);
  cursor: pointer;
  background: var(--menu-container);
  border: 1px solid var(--primary-color);
  border-radius: 2rem;
  padding: 0.25rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-menu-container-side-toggle > i {
  font-size: 1.5rem;
}

.fade-out {
  transform: translateX(-100%);
  opacity: 0;
}

.fade-in {
  transform: translateX(0%);
  opacity: 1;
  transition: transform ease-in 0.25s, opacity ease-in 0.25s;
}

.navbar-current-page {
  color: var(--text-bright);
  font-weight: bold;
  margin-left: auto;
}

.navbar-menu > button#toggle-btn,
.navbar-menu > a {
  background: var(--menu-container);
  border: 1px solid var(--primary-color);
  border-radius: 2rem;
  padding: 0.25rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: transparent;
}

.navbar-menu button#toggle-btn i,
.navbar-menu a i {
  width: unset;
  height: unset;
  font-size: unset;
  padding: 0;
  color: var(--menu-container);
  background-color: var(--secondary-color);
  border: none;
  transition: var(--transition);
  display: block;
}

.navbar-menu > button#toggle-btn:hover i {
  border: none;
  background-color: none;
}

.navbar-menu__icon-container > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-menu__icon-container > a > svg {
  font-size: 1.25rem;
  background-color: transparent;
}

@media (max-width: 1140px) {
  .navbar-menu_container-links-sign > *:nth-child(n + 3) {
    display: none !important;
  }
}
