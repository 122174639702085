/* Hide default cursor only for non-touch devices */
@media (pointer: fine) {
    * {
      cursor: none !important;
    }
  }
  
  /* Custom Cursor */
  .custom-cursor {
    position: fixed;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    mix-blend-mode: difference;
    pointer-events: none; /* Prevents interaction */
    transform: translate(-50%, -50%);
    transition: transform 0.15s ease-out;
    z-index: 999999;
  }
  